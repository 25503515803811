import React from 'react'
import { graphql } from 'gatsby'

import { Image } from '../../utilities'
import ReactMarkdown from 'react-markdown'
import { Layout } from '../../components'

import * as styles from './supportArticle.module.scss'
import { sanitizeContent } from '../../utilities/sanitizeContent'

export default ({
  data: {
    supportArticlesJson
  },
}) => {

  const article = sanitizeContent(supportArticlesJson)

  const {
    issue,
    content,
    image,
    summary,
    tags,
    seo_image
  } = article

  return (
    <Layout title={issue} metaTags={tags} seo_image={seo_image} description={summary}>
      <article className={styles.section}>
        {image && (
        <div className={styles.hero}>
            <Image src={image} className={styles.image} container />
        </div>
        )}
        <h2 className={styles.title}>{summary}</h2>
        <div className={styles.body}>
          <ReactMarkdown>{content}</ReactMarkdown>
        </div>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
query ($slug: String!) {
  supportArticlesJson(slug: {eq: $slug}) {
    issue
    content
    image
    summary
    tags
    seo_image
  }
}
`